<script setup lang="ts"></script>
<template>
   <div class="projectBackground min-h-dvh flex flex-col justify-between">
      <div class="flex flex-col items-center">
         <div class="container px-8 md:px-4 lg:px-14">
            <LayoutUnAuthHeader></LayoutUnAuthHeader>
            <div class="my-6">
               <NuxtPage />
               <slot></slot>
            </div>
         </div>
      </div>
      <LayoutFooter></LayoutFooter>
      <WhatappContact is-right="true"></WhatappContact>
   </div>
</template>

<style scoped>
   .projectBackground {
      background-image: url('@/assets/image/background-gradient-left.png'),
         url('@/assets/image/background-gradient-right.png');
      background-repeat: no-repeat;
      background-position:
         0 0,
         100% 0;
   }
</style>
